import React from 'react' 

import './styles.css'

const DefaultContent = ({ children }) => (
  <div className="default-content content">
    {children}
  </div>
)

export default DefaultContent