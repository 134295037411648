import React from "react"
import { graphql } from "gatsby"
import { RichText } from 'prismic-reactjs'

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import DefaultContent from "../components/DefaultContent"

import '../styles/index.css'

const Terminos = ({ location, data }) => {
  const content = data?.allPrismicTerminos?.nodes?.[0]?.data

  return (
    <Layout pathname={location?.pathname}>
      <SEO title={content?.title?.text} />
      <div className="sidebar sidebar--left sidebar--hide-mobile">
        &nbsp;
      </div>
      <DefaultContent>
        <RichText render={content?.title?.raw} />
        <RichText render={content?.intro?.raw} />
        <div className="default-content--small">
          <RichText render={content?.text?.raw} />
        </div>
      </DefaultContent>
      <div className="sidebar sidebar--right sidebar--hide-mobile">
        &nbsp;
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allPrismicTerminos {
      nodes {
        data {
          intro {
            html
            text
            raw
          }
          text {
            html
            text
            raw
          }
          title {
            html
            text
            raw
          }
        }
      }
    }
  }
`

export default Terminos
